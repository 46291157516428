import React, { useEffect } from "react";
import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
    return twMerge(clsx(inputs))
}

export const useOutsideClick = (
    ref,
    callback
  ) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        callback(event);
      };
   
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
   
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, callback]);
  };