import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';

const FooterComponent = () => {
  return (
    <div id="contact">
      <Footer
        backgroundColor="rgb(10 10 10)"
        columns={[
          {
            icon: (
              <img src="./favicon.ico" alt="Alex Okarkau Logo" />
            ),
            title: 'Valeria Paliuka',
            url: 'https://aokarkau.com',
            description: '',
            openExternal: true,
            items: [{
              title: 'San Francisco, CA',
              description: '',
            }]
          },
          {
            title: 'Contact',
            items: [{
              title: 'Send an email',
              url: 'mailto:valeryia.paliuka@gmail.com',
              openExternal: true,
              description: '',
              icon: (
                <img src="https://cdn-icons-png.flaticon.com/512/281/281769.png" alt="Gmail Logo" />
              )
            }]
          },
          {
            title: 'Social',
            items: [{
              title: 'LinkedIn',
              url: 'https://www.linkedin.com/in/vpaliuka/',
              openExternal: true,
              description: '',
              icon: (
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/640px-LinkedIn_logo_initials.png" alt="Linkedin Logo" />
              )
            }]
          }
        ]}
        bottom={`© ${new Date().getFullYear()} Valeria Paliuka`}
      />
    </div>
  );
}

export default FooterComponent;
