
import React from "react";
import { Timeline } from "./timeline";

export default function TimelineDemo() {
  const data = [
    {
      title: "2023-2024",
      content: (
        <div>
          <p
            className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-2">
            CMA
          </p>
          <p
            className="text-neutral-800 dark:text-neutral-400 text-xs md:text-sm font-normal mb-8">
            Institute of Management Accountants
          </p>
          <div className="flex">
            <img
              src="./cma.png"
              className="rounded-lg object-cover h-12 sm:h-24 shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]" />
            <p className="pl-4">External Financial Reporting Decisions, Planning, Budgeting, and Forecasting, Performance Management, Cost Management, Internal Controls, Technology and Analytics, Financial Statement Analysis, Corporate Finance, Decision Analysis, Risk Management, Investment Decisions, Professional Ethics</p>
          </div>
        </div>
      ),
    },
    {
      title: "05/2023 - Present",
      content: (
        <div>
          <p
            className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-2">
            Senior Private Client Banker
          </p>
          <p
            className="text-neutral-800 dark:text-neutral-400 text-xs md:text-sm font-normal mb-8">
            JPMorgan Chase & Co, San Francisco, CA
          </p>
          <div className="flex">
            <img
              src="./jpmc.jpeg"
              className="rounded-lg object-cover h-12 sm:h-24 shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]" />
            <p className="pl-4">Open personal and business accounts; facilitate transfers and wires. Review clients’ financial statements to forecast anticipated account activity and find best product. Perform daily personal and office balancing. Monitor accounts with overdraft activity and payments with insufficient funds. Provide reports on client portfolios. Performing analysis for suspicious activity, maintaining KYC records.</p>
          </div>
        </div>
      ),
    },
    {
      title: "(Jun - Nov) 2021",
      content: (
        <div>
          <p
            className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-2">
            Google Data Analytics Certificate
          </p>
          <p
            className="text-neutral-800 dark:text-neutral-400 text-xs md:text-sm font-normal mb-8">
            Online / Coursera
          </p>
          <div className="flex">
            <img
              src="./google_cert.png"
              className="rounded-lg object-cover h-12 sm:h-24 shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]" />
            <p className="pl-4">Data Aggregation, Data Analytics, Data calculations, Data Cleaning, Data Ethics, Data Visualization, Presentations, Problem Solving, R, Spreadsheets, SQL, Structural Thinking</p>
          </div>
        </div>
      ),
    },
    {
      title: "2018-2024",
      content: (
        <div>
          <p
            className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-2">
            Senior Banker
          </p>
          <p
            className="text-neutral-800 dark:text-neutral-400 text-xs md:text-sm font-normal mb-8">
            First Republic Bank, San Francisco, CA
          </p>
          <div className="flex">
            <img
              src="./fr_logo.jpeg"
              className="rounded-lg object-cover h-12 sm:h-24 shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]" />
            <p className="pl-4">Open personal and business accounts; facilitate transfers and wires. Review clients’ financial statements to forecast anticipated account activity and find best product. Perform daily personal and office balancing. Monitor accounts with overdraft activity and payments with insufficient funds. Provide reports on client portfolios. Performing analysis for suspicious activity, maintaining KYC records.</p>
          </div>
        </div>
      ),
    },
    {
      title: "2016-2017",
      content: (
        <div>
          <p
            className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-2">
            Senior Business Leasing Specialist
          </p>
          <p
            className="text-neutral-800 dark:text-neutral-400 text-xs md:text-sm font-normal mb-8">
            ASB Leasing, Minsk, Belarus
          </p>
          <div className="flex">
            <img
              src="./asb_leasing_logo.png"
              className="rounded-lg object-cover h-12 sm:h-24 shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]" />
            <p className="pl-4">Based on performance, advanced to support clients in fulfilling financial obligation via personal and business financial strategies. Analyzed lessee’s financial statements, solvency calculations, and financial condition reports. Created client financial analysis reports based on quarterly statements. Prepared delivery contracts. Guided clients on completing forecasting/budgeting forms. Managed past-due payments. Created leasing transaction profitability analysis reports.</p>
          </div>
        </div>
      ),
    },
    {
      title: "2015 – 2016",
      content: (
        <div>
          <p
            className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-2">
            Business Lending Analyst
          </p>
          <p
            className="text-neutral-800 dark:text-neutral-400 text-xs md:text-sm font-normal mb-8">
            Belarusbank, Minsk, Belarus
          </p>
          <div className="flex">
            <img
              src="./belarusbank_logo.png"
              className="rounded-lg object-cover h-12 sm:h-24 shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]" />
            <p className="pl-4">Analysis of customers’ financial statements, solvency, and creditworthiness to mitigate risk and enable sound decision making. Prepared closing remarks concerning advisability of loan approvals, bank guarantees. Created client financial analysis reports based on quarterly statements. Prepared contracts. Produced interest accrual calculations.</p>
          </div>
        </div>
      ),
    },
    {
      title: "2011 – 2015",
      content: (
        <div>
          <p
            className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-2">
            Bachelor of Arts in Finance
          </p>
          <p
            className="text-neutral-800 dark:text-neutral-400 text-xs md:text-sm font-normal mb-8">
            Belarusian State Economic University, Minsk, Belarus
          </p>
          <div className="flex">
            <img
              src="./bseu_logo.jpeg"
              className="rounded-lg object-cover h-12 sm:h-24 shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]" />
            <p className="pl-4">Major in “Finance and Credit”. Gained comprehensive knowledge in various areas of financial and credit relations, and practical skills of financial reporting, as well as developed the ability of analytical thinking and creative approach to solving financial tasks. Furthermore, got up-to-date knowledge on financial management, financial institutions, fiscal relations, acquired skills in the organization of finance for private and public enterprises, learned the specifics of the government financial management.</p>
          </div>
        </div>
      ),
    },
  ];
  return (
    (<div className="w-full">
      <Timeline data={data} />
    </div>)
  );
}
