import { motion } from "framer-motion";
import { HeroHighlight, Highlight } from "./hero-highlight";

export default function About() {
  return (
    (
    <div className="pb-6">
    <HeroHighlight className="flex flex-col justify-center items-center">
      <motion.h1
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={{
          opacity: 1,
          y: [20, -5, 0],
        }}
        transition={{
          duration: 0.5,
          ease: [0.4, 0.0, 0.2, 1],
        }}
        className="px-4 md:text-4xl lg:text-5xl font-bold text-neutral-700 dark:text-white max-w-4xl leading-relaxed lg:leading-snug text-center mx-auto ">
        <Highlight className="text-black dark:text-white text-center p-4">
          Hello! My name is Valeria.
        </Highlight>
      </motion.h1>
      <br />
      <div className="max-w-[600px] text-center">
      <p className="p-4">I enjoy analyzing financial data to evaluate risks, discover trends, and answer key questions along the way. My interest in finance and data analysis has been growing since I started to work in Banking as a lending analyst.</p>
      <br />
      <p className="p-4">Since then I’ve had the privilege of working in 2 Banks and a Leasing company. My main focus these days is to help companies make informed business decisions.</p>
      <br />
      <p className="p-4">Additionally, I have obtained a Google Data Analytics certificate, which has equipped me with valuable skills to enhance my data analysis capabilities.</p>
      <br />
      <p className="p-4">Here are a few tools and platforms I’ve been working with:</p>
      <div className="text-left">
                  <p><i>• Spreadsheets (Google Sheets or Microsoft Excel)</i></p>
                  <p><i>• SQL</i></p>
                  <p><i>• Tableau</i></p>
                  <p><i>• Rstudio</i></p>
                  <p><i>• Kaggle</i></p>
      </div>
      </div>
    </HeroHighlight>
    </div>)
  );
}
