import IndexPage from './components/v2';

function App() {
  const OUT_OF_SERVICE = false;

  return (
    <div className="App">
      {
        OUT_OF_SERVICE ?
        <>
          <h1>Out of service</h1>
        </>
        :
        <>
          <IndexPage />
        </>
      }

    </div>
  );
}

export default App;
