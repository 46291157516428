import React from "react";
import { Carousel, Card } from "./apple-cards-carousel";

const BellabeatContent = () => {
  return (
    <>
      <div
        className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-6 rounded-3xl mb-4"
      >
        <iframe
          width="100%"
          title="bellabeat"
          src="https://vpaliuka-bellabeat-project.web.app/"
          className="iframe h-[70vh]"
        />
      </div>
    </>
  );
};
const RMenuContent = () => {
  return (
    <>
      <div
        className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4"
      >
        <iframe
          width="100%"
          title="RMenu Analytics Project"
          src="/rmenu_project/index.html"
          className="iframe h-[70vh]"
        />
      </div>
    </>
  );
};

const data = [
  {
    category: "How to step up a game for a Wellness Technology Company",
    title: "R | Data Cleaning | Data Visualization | R Markdown",
    src: "https://support.bellabeat.com/hc/article_attachments/360000947100/sleep_goal.png",
    content: <BellabeatContent />,
  },
  {
    category: "Setting Up Restaurant Analytics for Smart Menu Application",
    title: "SQL | Schema Declare | Data Visualization",
    src: "https://img.freepik.com/premium-photo/french-restaurant-ambiance-3d-featuring-vintage-cafe-aesthetic-vertical-mobile-wallpaper_795881-30532.jpg",
    content: <RMenuContent />,
  },
];

export function AppleCardsCarouselDemo() {
  const cards = data.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  return (
    <div className="w-full h-full py-20">
      <h2 className="max-w-7xl pl-8 mx-auto text-xl md:text-5xl font-bold text-neutral-800 dark:text-neutral-200 font-sans">
        Projects
      </h2>
      <Carousel items={cards} />
    </div>
  );
}

export function Projects() {
  return (
    <div className="w-full h-full py-20">
      <AppleCardsCarouselDemo />
    </div>
  );
}
