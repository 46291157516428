import React, { useState } from "react";
import { cn } from "../../lib/utils";
import { WavyBackground } from './components/wavy-background'
import { HoveredLink, Menu, MenuItem, ProductItem } from "./components/navbar-menu";
import About from "./components/about";
import TimelineComponent from "./components/timeline-component";
import FooterComponent from "../Footer";
import { Projects } from "./components/projects";


function Navbar({ className }) {
  const [active, setActive] = useState(null);
  return (
    <div
      className={cn("fixed top-10 inset-x-0 max-w-2xl mx-auto z-50 dark", className)}
    >
      <Menu setActive={setActive}>
        <div onClick={() => { { window.location.replace('#about') } }}>
          <MenuItem
            setActive={setActive}
            active={false}
            item="About"
          >
            {/* <div className="flex flex-col space-y-4 text-sm">
            <HoveredLink href="/web-dev">Web Development</HoveredLink>
            <HoveredLink href="/interface-design">Interface Design</HoveredLink>
            <HoveredLink href="/seo">Search Engine Optimization</HoveredLink>
            <HoveredLink href="/branding">Branding</HoveredLink>
            </div> */}
          </MenuItem>
        </div>
        <div onClick={() => { window.location.replace('#cv') }}>
          <MenuItem
            setActive={setActive}
            active={false}
            item="CV"
          >
            {/* <div className="  text-sm grid grid-cols-2 gap-10 p-4">
            <ProductItem
            title="Algochurn"
            href="https://algochurn.com"
            src="https://assets.aceternity.com/demos/algochurn.webp"
            description="Prepare for tech interviews like never before."
            />
            <ProductItem
            title="Tailwind Master Kit"
            href="https://tailwindmasterkit.com"
            src="https://assets.aceternity.com/demos/tailwindmasterkit.webp"
            description="Production ready Tailwind css components for your next project"
            />
            <ProductItem
            title="Moonbeam"
            href="https://gomoonbeam.com"
            src="https://assets.aceternity.com/demos/Screenshot+2024-02-21+at+11.51.31%E2%80%AFPM.png"
            description="Never write from scratch again. Go from idea to blog in minutes."
            />
            <ProductItem
            title="Rogue"
            href="https://userogue.com"
            src="https://assets.aceternity.com/demos/Screenshot+2024-02-21+at+11.47.07%E2%80%AFPM.png"
            description="Respond to government RFPs, RFIs and RFQs 10x faster using AI"
            />
            </div> */}
          </MenuItem>
        </div>
        <div onClick={() => window.location.replace('#projects')}>
          <MenuItem
            setActive={setActive}
            active={false}
            item="Projects"
          >
            {/* <div className="flex flex-col space-y-4 text-sm">
            <HoveredLink href="/hobby">Hobby</HoveredLink>
            <HoveredLink href="/individual">Individual</HoveredLink>
            <HoveredLink href="/team">Team</HoveredLink>
            <HoveredLink href="/enterprise">Enterprise</HoveredLink>
          </div> */}
          </MenuItem>
        </div>
        <MenuItem setActive={setActive} active={active} item="Contact">
          <div className="  text-sm grid grid-cols-2 gap-10 p-4">
            <ProductItem
              title="Linkedin"
              href="https://www.linkedin.com/in/vpaliuka"
              src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
              description="Let's Connect"
            />
            <ProductItem
              title="Gmail"
              href="https://mail.google.com/mail/?view=cm&fs=1&to=valeryia.paliuka@gmail.com"
              src="https://upload.wikimedia.org/wikipedia/commons/7/7e/Gmail_icon_%282020%29.svg"
              description="Send an Email"
            />
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default function index() {
  return (
    <div className="bg-zinc-900 text-white font-mono dark">
      <WavyBackground className="max-w-4xl mx-auto pb-40 pt-20 flex flex-col justify-center items-center">
        <div className="w-[325px] pb-4 ">
          <img className="rounded-lg" alt="profile img" src="/92E46D79-BA4C-4CC8-86DA-039D48B084F3.webp" loading="lazy" width="" height="" />
        </div>
        <p className="text-2xl md:text-4xl lg:text-7xl text-white font-bold inter-var text-center">
          Valeria Paliuka
        </p>
        <p className="text-base md:text-lg mt-4 text-white font-normal inter-var text-center">
          Financial Analyst, CMA
        </p>
      </WavyBackground>
      <nav className=""><Navbar className="top-2" /></nav>
      <div className="" id="about">
        <About />
      </div>
      <div className="" id="cv"><TimelineComponent /></div>
      <div className="" id="projects"><Projects /></div>
      <FooterComponent />
    </div>
  )
}
